import { service } from './request'
 
export default{
// post请求
   postDataList:(params) => service.post('/xxxx', params),
 
// get请求
    getDataList:(params) => service.get('/xxxx', {params}),
 
//https://cx.ringzle.com/cxcanteen-api/login
// post请求
    posttest:(params) => service.post('/app/login', params),
 


    
/**
 * @description:  人口库人员详细信息
 * @params {*}   idCard 身份证号
 * @return {*}
 * */
 getOrgPersonBaseInfo:(params)=>service.get('/api/org/baseInfo/'+params,{}),
 

/**
 * @description:  人口库获取社区信息
 * @params {*}  
 * @return {*}
 * */
 getOrgCommunityList:(params)=>service.get('/api/org/communityList/',{params:params}),
  

/**
 * @description: 人口库获取小区信息
 * @params {*}   communityId 社区id  
 * @params {*}   villageId 小区id
 * @return {*}
 * */
 getOrgLiveList:(params)=>service.get('/api/org/liveList/',{params:params}),
  
  /**
 * @description: 人口库获取楼栋信息
 * @params {*}   liveId 小区id
 * @return {*}
 * */
   getOrgStoreList:(params)=>service.get('/api/org/storeList/',{params:params}),



 /**
 * @description: 人口库获取房屋信息
 * @params {*}   limit 显示条数
 * @params {*}   liveId 小区id
 * @params {*}   page  页
 *  @params {*}  storeId 楼栋id 
 * @return {*}
 * */
  getOrgPublicPage:(params)=>service.get('/api/org/liveList/',{params:params}),
 
/**
 * @description: 获取所有人员信息
 * @params {*}   limit  page listType personName
 * @return {*}
 * */
  getPersonList:(params)=>service.get('/api/page',{params:params}),

 
/**
 * @description: 管控人员详细信息
 * @params {*}   personId 管控人员ID
 * @return {*}
 * */
    getPersoninfo:(params) => service.get('/api/detail/'+params, {}),


 /**
 * @description:  人员信息上报
 * @params {*}    person Json add
 * @return {*}
 * */
    addPersonInfo:(params)=>service.post('/api/add',params),


 /**
 * @description:  人员信息审核
 * @params {*}   person Json
 * @return {*}
 * */
      controlApprove:(params)=>service.post('/api/controlApprove',params),


   /**
    * @description:  人员信息调整 （改变管控状态、隔离时间）
    * @params {*}    person Json  
    * @return {*}
    * */
   controlUpdate:(params)=>service.post('/api/controlUpdate',params),



      /**
       * @description:  管控人员每日任务填报
       * @params {*}    json 
       * @return {*}
       **/
    getSubmit:(params) => service.post("/api/submit", params),

      /**
       * @description:  管控人员每日任务填报
       * @params {*}    json 
       * @return {*}
       **/
       getTaskSubmit:(params) => service.post("/api/tasksubmit", params),
  

        /**
      * @description:  填报任务分页
      * @params {*}   limit 显示条数
      * @params {*}   personName 人员姓名
      * @params {*}   page  页
      *  @params {*}  dataType  数据:1当日信息；2全部
      * @return {*}
    * */
         getvhpage:(params)=>service.get('/api/vhpage',{params:params}),

         /**
           * @description:  填报任务详情
           * @params {*}   Id   
           * @return {*}
         * */
          getvhDetail:(params)=>service.get('/api/vhdetail/'+params, {}),

// ///stayhome-api/api/loginByToken/{token}

      /**
       * @description:  管控人员每日任务填报
       * @params {*}    token  userType
       * @return {*}
       **/
       getloginByToken:(token,userType) => service.post("/api/loginByToken/"+token+"/"+userType, {}),

      /**
       * @description:  获取测试token
       * @return {*}
      * */
      getAuthToken:(params)=>service.get('/api/getAuthToken',{}),

 
      /**
      * @description:  数据字典数据
      * @return {*}
      * */
      getDictList:(params)=>service.get('/api/all',{}),

       

       /**
       * @description:  根据手机号模拟登录功能
       * @params {*}    json 
       * @return {*}
       **/
       loginByMobile:(token,userType) => service.post("/api/loginByMobile/"+token+"/"+userType, {}),
 
      /**
      * @description: 获取用户信息
      * @return {*}
      * */
       getUserInfo:(params)=>service.get('/api/userInfo',{}),


      /**
      * @description: 上传图片
      * @return {*}
      * */
       uploadFile:(params)=>service.post('/api/uploadFile',params , {header:{ 'Content-Type': 'multipart/form-data' }}),
        

  /**
    * @description:  转运分页
   * @params {*}   limit 显示条数
   * @params {*}   personName 人员姓名
   * @params {*}   page  页
   *  @params {*}  status  转运状态:未转运NotTransshipped,转运中InTransit,Transshipped已转运
    * @return {*}
    * */
   gettspage:(params)=>service.get('/api/tspage',{params:params}),

   /**
* @description:  转运详情
* @params {*}   id  
* @return {*}
* */
 gettsDetail:(params) => service.get('/api/tsdetail/'+params, {}),

     
 
}