<template>
  <van-button
    v-if="dataForm.userType != ''"
    icon="exchange"
    type="primary"
    size="small"
    block
    plain
    style="margin-top: 5px; text-align: left"
    @click="ChangeUserType"
    >{{ dataForm.userType == 2 ? "切换到管控人员" : "切换到司机" }}</van-button
  >
  <router-view @changeTitle="changeTitle($event)" />
  <!-- 网格人员 v-if  用户类型== 网格人员 -->
  <!-- <van-tabbar
    v-model="dataForm.active"
    @change="menuChange"
    :placeholder="true"
  
    fixed=true
  > -->
  <!-- <van-tabbar-item name="alllist" icon="friends-o" to="/allList"
      >管控人员</van-tabbar-item>
    <van-tabbar-item name="todolist" icon="todo-list-o" to="/todolist"
      >工作填报</van-tabbar-item >
    <van-tabbar-item name="addPersonInfo" icon="todo-list-o" to="/addPersonInfo"
      >主动上报</van-tabbar-item
    >
  </van-tabbar>-->
  <!-- 转运司机 v-if  用户类型== 转运司机  -->
  <van-tabbar
    v-model="dataForm.active"
    @change="menuChange"
    :placeholder="true"
    fixed
    v-if="dataForm.userType == 2"
  >
    <van-tabbar-item
      name="NotTransshipped"
      icon="friends-o"
      :to="{ name: '待转运列表', query: { status: 'NotTransshipped' } }"
      >待转运</van-tabbar-item
    >
    <van-tabbar-item
      name="InTransit"
      icon="home-o"
      :to="{ name: '转运中列表', query: { status: 'InTransit' } }"
      >转运中</van-tabbar-item
    >
    <van-tabbar-item
      name="Transshipped"
      icon="home-o"
      :to="{ name: '已转运列表', query: { status: 'Transshipped' } }"
      >已转运</van-tabbar-item
    >
  </van-tabbar>
  <!-- 管控人员 v-if  用户类型== 管控人员 -->

  <van-tabbar
    v-if="dataForm.userType == 1"
    v-model="dataForm.active"
    @change="menuChange"
    :placeholder="true"
    fixed
  >
    <van-tabbar-item
      name="everySubmitList"
      icon="friends-o"
      to="/everySubmitList"
      >填报记录</van-tabbar-item
    >
    <van-tabbar-item name="everySubmit" icon="home-o" to="/everySubmit"
      >每日填报</van-tabbar-item
    >
  </van-tabbar>

  <!-- 事务局领导 v-if  用户类型== 事务局领导  -->
  <!-- <van-tabbar
    v-model="dataForm.active"
    @change="menuChange"
    :placeholder="false"
     v-if="dataForm.PersonInfo.type==4"
  >
    <van-tabbar-item name="report" icon="friends-o" to="/report"
      >全部人员</van-tabbar-item
    >
    <van-tabbar-item name="homeList" icon="home-o" badge="5" to="/homeList"
      >统计分析</van-tabbar-item
    >
  </van-tabbar> -->
  <div style="height: 30px"></div>

  <!-- 开启底部安全区适配 -->
  <van-number-keyboard safe-area-inset-bottom />
</template>
<script>
import { ref, reactive } from 'vue';
import { Notify } from 'vant';
import { useRouter } from 'vue-router'
import Api from './util/Api';
export default {
  setup() {
    const dataForm = reactive({
      active: ref('alllist'),
      leftArrow: false,
      pageTitle: "居家隔离人员管控",
      navTitle: '',
      userType: 1,
      userInfo: {
        //"id": "",
        // "creator": "",
        // "createDate": "",
        // "username": "",
        // "password": "",
        // "realName": "",
        // "headUrl": null,
        // "gender": 1,
        // "email": "",
        // "mobile": "",
        // "deptId": "",
        // "userType": null,
        // "superAdmin": 0,
        // "status": 1,
        // "updater": "",
        // "updateDate": "",
        // "deptName": null,
        // "idCard": null,
        // "comment": null,
        // "userId": "",
        // "unitId": "",
        // "grid": "",
        "roleCodes": []
      }, token: ''
    });

    const menuChange = () => {
      dataForm.navTitle = '';
      //alert(name)  
    };
    const changeTitle = (name) => {

      dataForm.navTitle = router.currentRoute.value.name;
      //alert(name)
    };

    const router = useRouter()
    const getUrlKey = (name) => {
      // eslint-disable-next-line no-sparse-arrays
      return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
    }
    return { dataForm, menuChange, changeTitle, router, getUrlKey };
  },
  
     watch: {
    '$route'(val) { 
        this.dataForm.active =val.path.replace("/",'') 
    }
  }, mounted() {


    if (location.href.indexOf('/login') >= 0) {
      this.dataForm.userType=''
      window.localStorage.setItem("token", '')
      window.localStorage.setItem('role', '')
      this.dataForm.role = ''
    }
    let sourceToken = this.getUrlKey('token');
    let phone = this.getUrlKey('phone');
    if (sourceToken != null && sourceToken != '') {
      this.dataForm.token = ''
        this.dataForm.userType=1
      window.localStorage.setItem('token', '')
      this.login(sourceToken)
    } else if (phone != null && phone != '') {
      this.dataForm.token = ''
         this.dataForm.userType=1
      window.localStorage.setItem('token', '')
      this.LocalHost(phone)
    }


    //  this.initdata(id)

    // 加载所有字典数据
    Api.getDictList().then((res) => {

      this.$store.state.Dict = res.data

    })

  }, activated() {
    if (location.href.indexOf('/login') >= 0) {
        this.dataForm.userType=''
      window.localStorage.setItem("token", '')
      window.localStorage.setItem('role', '')
      this.dataForm.role = ''
    }
    let sourceToken = this.getUrlKey('token');
    let phone = this.getUrlKey('phone');
    if (sourceToken != null && sourceToken != '') {
      this.dataForm.token = ''
      window.localStorage.setItem('token', '')
      this.login(sourceToken)
    } else if (phone != null && phone != '') {
      this.dataForm.token = ''
      window.localStorage.setItem('token', '')
      this.LocalHost(phone)
    }


    //  this.initdata(id)

    // 加载所有字典数据
    Api.getDictList().then((res) => {

      this.$store.state.Dict = res.data

    })

  }
  , methods:
  {
    login(token) {

      window.localStorage.setItem('token', this.dataForm.token)
      Api.getloginByToken(token, this.dataForm.userType).then((res) => {
        if (res.code == 0) {

          window.localStorage.setItem('token', res.data.token)
          this.dataForm.token = res.data.token
        } else {

          Notify({ type: 'danger', message: res.msg });

        }
        if (this.dataForm.userType == 1) {
          this.router.replace('everySubmitList')


        } else if (this.dataForm.userType == 2) {
          this.router.replace('transitList')
        }

      })
    },
    getDictLabel(type, value) {
      try {
        return this.$store.state.Dict.find(p => p.dictType == type).dataList.find(p => p.dictValue == value).dictLabel
      } catch {
        return ''
      }
    }
    ,
    LocalHost(phone) {

      window.localStorage.setItem('token', this.dataForm.token)
      Api.loginByMobile(phone, this.dataForm.userType).then((res) => {
        if (res.code == 0) {
          //登录成功
          this.dataForm.token = res.data.token
          window.localStorage.setItem('token', res.data.token)

          //this.getUserinfo();
        } else {
          Notify({ type: 'danger', message: res.msg });

        }
        if (this.dataForm.userType == 1) {
          this.router.replace('everySubmitList')


        } else if (this.dataForm.userType == 2) {
          this.router.replace('transitList')
        }
      })
    },
    getUserinfo() {
      Api.getUserInfo().then((res) => {

        if (res.code == 0) {
          this.dataForm.userInfo = res.data

          // let role = this.dataForm.userInfo.roleCodes.length > 0 ? this.dataForm.userInfo.roleCodes[0] : ""
          // window.localStorage.setItem('role', role)
          // switch (role) {
          //   case "Leader":
          //     this.router.replace("allList")
          //     break;
          //   case "TransferDriver":
          //     this.router.replace("transitList")
          //     break;
          //   case "GridPerson":
          //     this.router.replace("allList")
          //     break;


          // }

        }

      })

    }, ChangeUserType() {
      if (this.dataForm.userType == 1) {
        this.dataForm.userType = 2

      } else if (this.dataForm.userType == 2) {
        this.dataForm.userType = 1
      }


      let sourceToken = this.getUrlKey('token');
      if (sourceToken != null && sourceToken != '') {
        this.dataForm.token = ''
        window.localStorage.setItem('token', '')
        this.login(sourceToken)
      }

      let phone = this.getUrlKey('phone');
      if (phone != null && phone != '') {
        this.dataForm.token = ''
        window.localStorage.setItem('token', '')
        this.LocalHost(phone)
      }


    }

  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
