import axios from 'axios'
import { Notify } from 'vant/lib/notify/function-call';
import qs from 'qs'
// 获取环境变量
export const getBaseURI = () => { 

    let env = process.env.VUE_APP_NODE_ENV ||process.env.NODE_ENV
      
    //    开发环境  
    if (env === 'dev'||env=='development') {
         //  return 'https://bus.ssjkq.com/zdrqgk/stayhome-api';
      //   return 'https://s.ringzle.com/stayhome-api';
       return 'https://m2.ringzle.com/stayhome-api';
      //  return 'http://192.168.1.102:9022/stayhome-api';
       
    }

    // 集成测试环境
    if (env === 'prod:sit') {
        return 'https://s.ringzle.com/stayhome-api';
    }

    if (env=== 'prod:uat') {
        return 'https://s.ringzle.com/stayhome-api';
    }
    // 生产环境
    if (env=== 'prod'||env === 'production') {
        return './stayhome-api'
    } 

}

export const BaseUrl = getBaseURI()

export const service = axios.create({
    timeout: 5000,
    baseURL: getBaseURI(),
})

 
/**
 * req 拦截器
 */
 service.interceptors.request.use((config) => {
    // 配置头部
    let token = window.localStorage.getItem('token')
    if (token != "") {
        config.headers['token'] = token

    }
    // if (/^application\/x-www-form-urlencoded/.test(config.headers.post['Content-Type'])) {
    //     config.data = qs.stringify(config.data)
    //   }

    // if(config.method=='post'){
    //     config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    //     config.data = qs.stringify(config.data);
    //     // config.data = qs.stringify(config.data)
    // }  

    return config
}, (error) => {
    Notify({ type: 'danger', message: JSON.stringify(error) });
 
  return Promise.reject(error);
});

/**
 * res 拦截器
 */
service.interceptors.response.use((response) => {
    const res = response.data;
    // 判断token 失效
  
    if (res.error) {
        if (process.env.NODE_ENV !== 'production') {
            console.error(res);
        }
        Notify({ type: 'danger', message: JSON.stringify(res) });
 
       return Promise.reject(res);
    }
    return Promise.resolve(res);
});

