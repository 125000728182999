import { createRouter, createWebHashHistory,createWebHistory } from 'vue-router'
 

const routes = [
  {
    path: '/alllist',
    name: '全部人员列表',
    component: () => import(/* webpackChunkName: "about" */ '../views/alllist.vue')
  },
  {
    path: '/homelist',
    name: '待填报人员列表',
    component: () => import(/* webpackChunkName: "about" */ '../views/homelist.vue')
  },
  {
    path: '/todolist',
    name: '蜀经开重点人群管控系统',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/todolist.vue')
  },
  {
    path: '/PersonDetial',
    name: '人员详细信息',
    component: () => import(/* webpackChunkName: "about" */ '../views/PersonDetial.vue')
  },
  {
    path: '/homeEdit',
    name: '任务填报',
    component: () => import(/* webpackChunkName: "about" */ '../views/homeEdit.vue')
  },
  {
    path: '/addPersonInfo',
    name: '主动上报',
    component: () => import(/* webpackChunkName: "about" */ '../views/addPersonInfo.vue')
  }, 
  {
    path: '/addPersonInfo',
    name: '主动上报',
    component: () => import(/* webpackChunkName: "about" */ '../views/addPersonInfo.vue')
  },
  {
    path: '/addPersonInfo',
    name: '主动上报',
    component: () => import(/* webpackChunkName: "about" */ '../views/addPersonInfo.vue')
  },
  {
    path: '/PersonInfoCheck',
    name: '人员信息核查列表',
    component: () => import(/* webpackChunkName: "about" */ '../views/PersonInfoCheck.vue')
  },
  {
    path: '/PersonInfoCheckEdit',
    name: '人员信息核查编辑',
    component: () => import(/* webpackChunkName: "about" */ '../views/PersonInfoCheckEdit.vue')
  },
  {
    path: '/transitList',
    name: '待转运列表',
    component: () => import(/* webpackChunkName: "about" */ '../views/transitList.vue')
  }, 
  {
    path: '/transitList1',
    name: '转运中列表',
    component: () => import(/* webpackChunkName: "about" */ '../views/transitList1.vue')
  }, 
  {
    path: '/transitList2',
    name: '已转运列表',
    component: () => import(/* webpackChunkName: "about" */ '../views/transitList2.vue')
  }, 
  {
    path: '/transitEdit',
    name: '转运单填报',
    component: () => import(/* webpackChunkName: "about" */ '../views/transitEdit.vue')
  }, 
  
  {
    path: '/transitDetail',
    name: '转运单详情',
    component: () => import(/* webpackChunkName: "about" */ '../views/transitDetail.vue')
  }, 
  {
    path: '/report',
    name: '统计',
    component: () => import(/* webpackChunkName: "about" */ '../views/report.vue')
  }, 
  {
    path: '/everySubmit',
    name: '任务填报',
    component: () => import(/* webpackChunkName: "about" */ '../views/everySubmit.vue')
  }, 
  {
    path: '/everySubmitList',
    name: '填报记录',
    component: () => import(/* webpackChunkName: "about" */ '../views/everySubmitList.vue')
  }, 
  {
    path: '/login',
    name: '演示版登录',
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  },  

  
]

const router = createRouter({
  history: createWebHashHistory(), // createWebHistory(), 
  base:'/zdrqgk',
  routes
})
// 全局前置守卫，设置页面标题
router.beforeEach((to, from, next) => {
  // to and from are both route objects. must call `next`.
  // document.title = to.meta.title;
  document.title = to.matched[0]==null?'蜀经开重点人群管控系统': to.matched[0].name;
 // console.log(to);
if(to.path==from.path)
{
     // alert(to.name)
}
  
  next(); //必须写
});
export default router
